@import "assets/css/variables.scss";

:root {
    --height-customer-exists: 30px;
}

html {
    overflow-X: hidden;
}

.appContainer {
    flex: 1;
    padding-top: var(--margin);
}

.container {
    @media #{$mq-upto-l} {
        max-width: 9999px !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
    @media #{$mq-upto-s} {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

.toast-content-encompass {
    margin: -14px -28px -14px -14px;
    padding: 14px 28px 14px 14px;
    text-align: left;
}

.title {
    display: block;
    text-align: left;
    margin: 10px 0 25px;
}

.ordersearch__results {
    &__subtitle {
        text-align: left;
    }
}

.ordersearch-form {
    &__search {
        position: relative;
        padding-left: 30px;

        &__icon {
            position: absolute;
            top: 0;
            right: auto;
            bottom: 0;
            left: 0;
            width: 30px;
            border-bottom: solid 1px var(--color-border);
            text-align: center;

            .icon {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        
        &__field {
            margin-bottom: 20px;

            label {
                left: 10px !important;
            }

            input {
                width: 100%;
            }
        }
    }
}